import React, { Component, Suspense } from 'react'
import './App.css'

class App extends Component {
  constructor(props) {
    super(props)

    this.state = {
      components: ['Logo', 'LogoReverse', 'LogoInverse', 'LogoVertical'],
      componentsLoaded: [],
      componentIndex: 0,
    }

    this.handleClick = this.handleClick.bind(this)
  }

  loadComponent = (componentName) => {
    const componentLoaded = this.state.componentsLoaded.filter(c => c.name === componentName)[0]

    // load once
    if(!componentLoaded) {
      // const components = this.state.components.filter(c => c.name !== componentName)
      const component = React.lazy(() => import(`./components/${componentName}`))
      const componentObject = { name: componentName, component }

      console.log('import')

      this.setState({
        componentsLoaded: this.state.componentsLoaded.concat(componentObject)
      })

      return componentObject
    }

    return componentLoaded.component
  }

  handleClick = () => {
    console.log('click')

    const { components, componentsLoaded, componentIndex } = this.state
    const nextIndex = componentIndex >= components.length -1 ? 0 : componentIndex + 1
    const nextComponent = components[nextIndex]
    const componentIsLoaded = componentsLoaded.filter(c => c.name === components[nextIndex])[0]

    // preload component now so it is available at render()
    if (!componentIsLoaded) {
      this.loadComponent(nextComponent)
    }

    this.setState({ componentIndex: nextIndex })
  }

  componentWillMount() {
    const { components, componentsLoaded } = this.state

    if (!componentsLoaded.length) {
      this.loadComponent(components[0])
    }
  }

  render() {
    const { components, componentIndex } = this.state
    const LogoComponent = this.loadComponent(components[componentIndex])

    console.log('render')

    return (
      <div className="App">
        <header className="App-header" onClick={this.handleClick}>
          <Suspense fallback={<div>Loading...</div>}>
            <LogoComponent />
          </Suspense>
        </header>
      </div>
    )
  }
}

export default App
