import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(<App />, document.getElementById('root'));

// Logic can be plugged in to SW here.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register({
  onUpdate(registration)   {
    console.log('[info] updated cache, update app', registration)
  },
  onSuccess(registration)   {
    console.log('[info] cache created', registration)
  }
});
